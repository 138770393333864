import React, { Component} from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';


class Project extends Component {
        constructor(props) {
            super(props);
            this.state = {
            isModalOpen: false,
            };
            
            this.toggleModal = this.toggleModal.bind(this);
            
        }
        toggleModal() {
            this.setState({
                isModalOpen: !this.state.isModalOpen
            });
        }
        render() {
            return (
                <React.Fragment>
                    <div className="container">
                        <div className="row ">
                            <div className="col-12">
                            <div outline onClick={this.toggleModal}><b> Projects</b></div>
                            </div>
                        </div>
                    </div>
                    <div className=" container modal-skill">
                    <Modal className="my-modals"isOpen={this.state.isModalOpen} toggle={this.toggleModal} style={{maxWidth: '1000px', width: '100%', height:'100%', color:"white"}}>
                    <ModalHeader toggle={this.toggleModal}><h2 className="about-t">PROJECTS</h2></ModalHeader>
                    <ModalBody>
                    <div className=' row card-container-t'>
                        <div className='col-md-5  image-container-t'>
                            <img src="./assets/images/Tanian.jpeg" style={{maxWidth: '1000px', width: '100%', height:'100%'}} alt=""/>
                        </div>
                        <div className='col-md-5 card-content-t'>
                            <div className='card-title-tt'>
                                    <h2>Tanian</h2>
                            </div>
                            <div className='card-title-t'>
                                    <h4>Tanian is an experience which brings you closer to Ethiopian mysteries from the distant past. We take you there. Our objective is to create for you never-fading memories.</h4>
                            </div>
                        </div>
                        <div className='col-md-2 btn-t'>
                            <button>
                            <a href="https://tanianethiopiatours.com/" target="_blank">
                            Website
                            </a>
                            </button>
                        </div>
                    </div>
                    <div className=' row card-container-t'>
                        <div className='col-md-5 image-container-t'>
                            <img src="./assets/images/affection1.jpeg" style={{maxWidth: '1000px', width: '100%', height:'100%'}} alt=""/>
                        </div>
                        <div className='col-md-5 card-content-t'>
                            <div className='card-title-tt'>
                                <h2>Coffee Affection</h2>
                            </div>
                            <div className='card-title-t'>
                                <h4>This is responsive website that shows our organic coffee products/Coffee Affection accessible shop in USA. The tool I used for this project, HTML, CSS ,JAVA SCRIPT, REACT, REDUX,NODE JS.</h4>
                            </div>
                        </div>
                        <div className='col-md-2 btn-t'>
                            <button>
                                <a href="https://teff.ttadege.com" target="_blank">
                                Website
                                </a>
                            </button>
                        </div>
                    </div>

                    <div className='row card-container-t'>
                        <div className='col-md-5  image-container-t'>
                            <img src="./assets/images/tour1.jpeg" style={{maxWidth: '1000px', width: '100%', height:'100%'}}  alt="logo"/>
                        </div>
                        <div className='col-md-5 card-content-t'>
                            <div className='card-title-tt'>
                                <h2>Ethio Tour</h2>
                            </div>
                            <div className='card-title-t'>
                                <h4>This is a published Tour Guid Website. If you want to travel to Ethiopia and needed my website to help you navigate the best place. The tool I used for this project, HTML, CSS ,JAVASCRIPT, Bootstrap.</h4>
                            </div>
                        </div>
                        <div className='col-md-2 btn-t'>
                            <button>
                                <a href="https://tour.ttadege.com" target="_blank">
                                Website
                                </a>
                            </button>
                        </div>
                    </div>
                    <div className=' row card-container-t'>
                        <div className='col-md-5 image-container-t'>
                                <img src="./assets/images/timetax2.jpeg" style={{maxWidth: '1000px', width: '100%', height:'100%'}} alt=""/>
                        </div>
                        <div className='col-md-5 card-content-t'>
                            <div className='card-title-tt'>
                                <h2>Accounting service</h2>
                            </div>
                            <div className='card-title-t'>
                                <h4>In this digital era, having an online presence is not just an advantage it’s an absolute necessity. Our website as the mirror of our service such as Tax filing, Auditing and other accounting service. The tool I used for this project, HTML, CSS ,JAVASCRIPT, REACT, and REDUX.</h4>
                            </div>
                        </div>
                        <div className='btn-t col-md-2'>
                            <button>
                                <a href="https://www.mb.ttadege.com" target="_blank">
                                Website
                                </a>
                            </button>
                        </div>
                    </div>
                    <div className=' row card-container-t'>
                        <div className='col-md-5 image-container-t'>
                            <img src="./assets/images/vms.jpeg" style={{maxWidth: '1000px', width: '100%', height:'100%'}} alt=""/>
                        </div>
                        <div className='col-md-5 card-content-t'>
                            <div className='card-title-tt'>
                                <h2>Vending Machine Simulator</h2>
                            </div>
                            <div className='card-title-t'>
                                <h4>This project is used to select item and get price; accept coins; dispense items purchased and return change.The tool I am using for this project,Java </h4>
                            </div>
                        </div>
                        <div className='col-md-2 btn-t'>
                            <button>
                                Website
                            </button>
                        </div>
                    </div>
                    </ModalBody>
                    </Modal>
                </div>
            </React.Fragment>
        );
    }
}

export default Project;