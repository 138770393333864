import React, { Component } from 'react';
import Fly from './FlyComponent';
import Home from './HomeComponent';
import { Switch, Route, Redirect, withRouter} from 'react-router-dom';



class Main extends Component {
    render() {
        
        return (
        <div >
        <Fly />
            <Switch>
                <Route path='/home' component={Home} />
                <Redirect to='/home' />
            </Switch>
        </div>
        );
    };
}
export default Main;